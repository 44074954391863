.arrow-border-right {
  position: relative;
}
.arrow-border-right:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);

  border-width: 7px;
  margin-top: -7px;
}
.arrow-border-right.badge-success:after {
  border-left-color: #36767e;
}
.arrow-border-right.badge-danger:after {
  border-left-color: #dc5f45;
}
.cycle-timeline .vertical-timeline-element-date {
  position: absolute;
  top: 14px;
  font-size: 24px;
  font-weight: 800 !important;
  color: #36767e;
}

#sidebar {
  .nav-link {
    position: relative;
    line-height: 30px;

    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 2px;
      background: map-get($theme-colors, "primary");
      width: 100%;
      transform: scale(0);
      transition: 400ms;
    }

    &:hover,
    &[data-attr="active"] {
      &::after {
        transform: scale(1);
      }
    }
  }
}

.cell-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  padding: 5px;
}
.cell-div.cell-2x {
  height: 48px;
}
.no-padding-table {
  th {
    font-size: 12px;
    font-weight: 600;
    color: black;
    background: white;
  }
  td {
    padding: 0;
    font-size: 10px;
    color: black;
  }
}

.dish-caret-item {
  transform: translateX(0px);
  transition: 200ms;
  &:hover {
    transform: translateX(2px);
  }
}
