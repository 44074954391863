.cta-box {
  overflow: hidden;
  padding: 0 !important;
  height: 276px;
  border-radius: 50px;
}
.top-block {
  height: 80%;
  width: 100%;
  transition: 500ms;

  span {
    font-size: 20px;
    font-weight: bold;
    padding-top: 8px;
  }
}

.bottom-block {
  height: 50%;
  width: 100%;
  border-radius: 50px;
  transition: 500ms;
  transform: translateY(0%);
}

.cta-box:hover .top-block {
  height: 50%;
}
